.progress-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8%;
  gap: 10px;
}

.bar {
  width: 20px;
  height: 4px;
  border-radius: 2px;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
}

.bar.active {
  background-color: #000000;
}
