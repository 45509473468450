html {
  overflow: hidden;
}

.role-select-body {
  height: 100vh;
}

.role-select-container {
  min-height: 60vh;
  margin-top: 10%;
  margin-inline: 10%;
}

.role-select-footer {
  display: flex;
  margin-inline: 10%;
  justify-content: right;
}

.page-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 35px;
  margin-inline: 5.6%;
}

.prompt {
  margin-top: 7%;
  color: 000000;
  opacity: 30%;
  font-family: "Roboto";
  font-weight: 400;
  margin-inline: 5.6%;
  font-size: 18px;
}

.role-selection {
  transition: border 1.5s ease;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 92px;
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 6%;
}

.role-selection:hover {
  cursor: pointer;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.br-next-button {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 15vw;
  height: 5vh;
  text-align: center;
  border-radius: 50%;
  transition: background 2s ease;
}

.br-next-button:hover {
  cursor: pointer;
}

.next-button {
  color: black;
  font-size: 30px;
  align-items: center;
}

.circle-1,
.circle-2,
.circle-3 {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.circle-1 {
  background-color: #f3a712;
  bottom: 5%;
  left: -25%;
}

.circle-2 {
  background-color: #f3a712;
  bottom: -5%;
  left: -5%;
}

.circle-3 {
  background-color: #0a1045;
  bottom: -10%;
  left: -20%;
}

@media (min-width: 768px) {
  .role-select-container {
    height: 60vh;
  }
  .role-select-footer {
    margin-inline: 0%;
  }

  .role-selection {
    transition: border 1.5s ease;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 92px;
    background: white;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin-bottom: 4%;
  }
}

@media (max-height: 800px) {
  .circles {
    display: none;
  }

  .role-select-container {
    min-height: 58vh;
    margin-top: 10%;
    margin-inline: 10%;
  }

  .role-select-footer {
    display: flex;
    justify-content: right;
    min-height: 10vh;
  }
}
