.feature-modal-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.feature-modal {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-modal-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-modal-body {
  font-size: 16px;
  color: #575757;
}

@media (max-width: 700px) {
  .feature-modal-overlay {
    top: 0;
    height: 80vh;
    background-color: white;
  }

  .feature-modal {
    width: 65%;
  }
}
