.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.landing-title {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: white;
}

.landing-nav {
  display: flex;
}

.nav-button {
  margin-left: 20px;
  padding: 10px 20px;
  background-color: var(--leazy-blue);
  color: white;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  border: 2px solid var(--leazy-blue);
  border-radius: 10px;
  font-size: 16px;
}

.login-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #333;
  border: none;
}

.login-button .fa-lock {
  margin-right: 8px;
}

.signup-button {
  background-color: var(--leazy-blue);
  color: white;
  border-radius: 5px;
}

.signup-button:hover {
  background-color: #0a1045f0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.login-button:hover {
  cursor: pointer;
  color: black;
}

.web-landing {
  background: linear-gradient(150deg, #3c4488, #5a6fc2, white);
  width: 100%;
  overflow-x: hidden;
}

.slide {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

#slide1 {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

#slide2 {
  background-color: #f0f0f0;
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.text-content {
  flex: 1;
  padding: 20px;
  font-size: 40px;
}

.slide-title {
  font-family: "Inter", sans-serif;
  font-size: 72px;
}

.slide-description {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  text-align: center;
  max-width: 800px;
  line-height: 60px;
}

.landing-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.footer-links a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

.footer-icons {
  display: flex;
  justify-content: center;
}

.footer-icons a {
  margin: 0 10px;
  font-size: 24px;
  color: white;
}

.icons {
  width: 48px;
  height: 48px;
}

/* For the main content, leave navigation and footer untouched */
.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  height: 90vh;
}

.left-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newsletter-section {
  display: flex;
  align-items: center;
}

.email-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  width: 250px;
}

.subscribe-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--leazy-yellow);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.screenshots {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.screenshot {
  max-width: 250px;
  height: auto;
  margin: 10px;
}

.motto {
  font-weight: 400;
  text-align: center;
  color: white;
  font-family: "Roboto";
}

@keyframes slideIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.subscribe-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: var(--leazy-yellow);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.subscribe-button:hover {
  background-color: white;
  color: black;
}

.screenshots {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.screenshot {
  width: 250px;
  height: auto;
  margin: 10px;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.screenshots .screenshot:nth-child(2) {
  transform: scale(1.2);
}

.headline {
  font-size: 36px;
  font-weight: 400;
  color: white;
  margin-bottom: 25%;
  font-family: "Roboto", sans-serif;
}

.highlighted {
  font-size: 48px; /* Larger size for 'Find' */
  font-weight: 700; /* Bolder weight for 'Find' */
  color: #fbb03b; /* Use the highlight color (yellowish) */
}

.motto {
  font-weight: 400;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
