.mobile-landing-nav {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.95);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.mobile-landing-nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;
  width: 100%;
}

.mobile-landing-nav ul div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  position: relative;
}

.mobile-landing-nav ul div li,
ul div p {
  font-family: "Inter";
  font-weight: 400;
  margin: 5px 0 0 5px;
  font-size: 14px;
  color: #8b8982;
}

.mobile-landing-nav ul div.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  border-radius: 2px;
  height: 4px;
  background-color: #f5a623;
}

.mobile-landing-nav ul div:hover::after {
  content: "";
  position: absolute;
  transition: background-color 1s ease, width 1s ease;
}

.mobile-landing-nav ul div .fa-icon {
  font-size: 35px;
  color: #8b8982;
  margin-top: 10px;
}

.mobile-navbar-icon {
  font-size: 28px;
  color: #8b8982;
}
