.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 90%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-button {
  background: none;
  border: none;
  border-radius: 20px;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
}

.cropper-container {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  max-width: 100%;
  height: auto;
  max-height: 70vh;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #828282;
  outline-color: black;
  overflow: hidden;
  width: 100%;
}

.cropper-point {
  background-color: #eaeaea;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-line {
  background-color: #eaeaea;
}

.cropper-canvas,
.cropper-view-box,
.cropper-face {
  background-color: #fff !important;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10%;
}

.cropper-modal {
  background-color: white;
}

.crop-button,
.change-button {
  padding: 20px 30px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  color: #fff;
  background: var(--leazy-blue);
}

.change-button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 600px;
  }

  .cropper-container {
    max-height: 60vh;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
  }
}
