/* Mobile-specific styles */
.mobile-landing {
  background: linear-gradient(150deg, #3c4488, #5a6fc2, white);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* To push footer to bottom */
}

.mobile-header {
  text-align: center;
}

.mobile-title {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: white;
  margin-top: 20%; /* Move it further from top */
}

.mobile-content {
  display: flex;
  flex-direction: column;
  margin-top: 40%;
  align-items: center;
  flex-grow: 1; /* Take up remaining space */
}

.motto {
  font-weight: 400;
  text-align: center;
  color: white;
  font-family: "Roboto";
  font-size: 20px;
  margin-bottom: 10%; /* Give space below motto */
}

.newsletter-section-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width */
}

.email-input-mobile {
  font-size: 16px;
  padding-left: 5%;
  height: 50px;
  width: 85%;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
}

.subscribe-button-mobile {
  padding: 12px;
  min-height: 60px;
  font-size: 16px;
  width: 90%; /* Take 90% of screen width */
  max-width: 500px;
  background-color: #fbb03b;
  color: white;
  border: none;
  border-radius: 10px;
}

.mobile-headline {
  font-size: 36px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin-bottom: 25%;
  font-family: "Roboto", sans-serif;
}

.highlighted {
  font-size: 48px; /* Larger size for 'Find' */
  font-weight: 700; /* Bolder weight for 'Find' */
  color: #fbb03b; /* Use the highlight color (yellowish) */
}

.motto {
  font-weight: 400;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
