.guidelines-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Roboto", sans-serif;
}

.guidelines-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
}

.guidelines-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", sans-serif;
}

.guidelines-content {
  margin-top: 20px;
}

.guidelines-content h3,
.guidelines-content h4 {
  font-family: "Inter", sans-serif;
}

.guidelines-content p,
.guidelines-content ul {
  font-family: "Roboto", sans-serif;
}

.guidelines-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
