/* STEP ONE PROPERTY INFO STYLING */
.property-info-step-one {
  min-height: 80vh;
}

.photo-upload-container {
  margin-top: 5%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 87.9%;
  min-height: 24vh;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
  overflow-x: auto;
  overflow-y: hidden;
}

.photo-upload-container div:first-child {
  font-size: 25px;
  color: black;
}

.photo-upload-container div:last-child {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #575757;
}

.property-details-container {
  min-height: 38vh;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  margin-inline: 5.66%;
}

.property-details-container input,
.property-details-container textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  color: #828282;
  font-size: 15px;
  font-family: "Inter";
}

.property-details-container input {
  height: 50px;
}

.property-details-container input:focus,
.property-details-container textarea:focus {
  outline: none;
  border: 1px solid black;
  color: black;
}

.property-details-container .description {
  padding-top: 20px;
  min-height: 15vh;
}

.property-details-container .description::placeholder {
  color: #828282;
  text-align: left;
}

.column-split-input {
  display: flex;
  gap: 20px;
}

.cost-input-wrapper {
  position: relative;
  flex: 2;
}

.dollar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #828282;
  font-size: 18px;
}

.room-cost {
  padding-right: 30px;
  height: 50px;
  padding: 10px;
  margin: 5px 0;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  box-sizing: border-box;
  color: #828282;
  font-size: 15px;
  font-family: "Inter";
}

.column-split-input input:first-child {
  flex: 2;
}

.address {
  display: flex;
  align-items: center;
}

.address p {
  margin-left: 3%;
  flex: 5 1;
  opacity: 40%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: black;
}

.address div {
  flex: 1;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media (max-height: 800px) {
  html {
    overflow: scroll;
  }

  .property-details-container {
    min-height: 44vh;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    margin-inline: 5.66%;
  }

  .prompt {
    margin-top: 7%;
    color: 000000;
    opacity: 30%;
    font-family: "Roboto";
    font-weight: 400;
    margin-inline: 5.6%;
    font-size: 17px;
  }
}

/* STEP TWO PROPERTY INFO STYLING */
.property-info-step-two {
  min-height: 80vh;
}

.description {
  min-height: 15vh;
  resize: none;
}

.property-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.property-type {
  margin: 0;
}

.three-col-amenities,
.more-amenities {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.amenity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  background-color: white;
  color: #828282;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
  height: 30px;
  padding: 0 10px;
}

.add-amenity {
  color: black;
  font-size: 15px;
  margin-right: 5px;
}

.amenity-name {
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  margin-inline: 2%;
}

.amenitites-header {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 600;
  margin-inline: 1%;
  margin-bottom: 5%;
}

.amenity.selected {
  background-color: black;
  color: white;
  border: 2px solid black;
}

.amenity.selected .add-amenity {
  color: white;
}

.view-more-btn {
  width: 100%;
  height: 50px;
  border-radius: 7px;
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.left-side-icon {
  position: absolute;
  left: 10px;
  color: black;
  pointer-events: none;
  font-size: 15px;
}

.icon-wrapper input {
  width: 100%;
  padding-left: 35px;
  box-sizing: border-box;
  height: 50px;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  color: #828282;
  font-size: 15px;
  font-family: "Inter";
}

.icon-wrapper input:focus {
  outline: none;
  border: 1px solid black;
  color: black;
}

@media (min-width: 800px) {
  html,
  body {
    overflow: auto;
  }

  .property-rooms {
    display: block;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .property-details-container {
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 5%;
    margin-inline: 5.66%;
  }

  .photo-upload-container {
    margin-top: 3%;
    margin-inline: 5.66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 87.9%;
    min-height: 24vh;
    border-radius: 12px;
    background-color: white;
    cursor: pointer;
  }

  .prompt {
    margin-top: 3%;
    color: 000000;
    opacity: 30%;
    font-family: "Roboto";
    font-weight: 400;
    margin-inline: 5.6%;
    font-size: 18px;
  }

  .amenitites-header {
    margin-bottom: 2%;
  }

  .amenities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-height: 15vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .address p {
    margin-left: 1%;
    flex: 5 1;
    opacity: 40%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    color: black;
  }

  .address div {
    flex: 0 1;
  }

  .view-more-btn {
    margin-bottom: 10%;
  }
}
