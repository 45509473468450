.error-container {
  margin-inline: 5.33%;
  margin-top: 5%;
}

.error-container span {
  color: #d62828;
  font-size: 14px;
}

.info-icon {
  color: #d62828;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  transition: background-color 0.3s;
}
