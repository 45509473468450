.progress-bar-container {
  max-width: 89.333%;
  margin-inline: 5.6%;
  margin-top: 5%;
  height: 8px;
  background-color: #ededed;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar-filler {
  height: 100%;
  transition: width 0.3s ease-in-out;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
