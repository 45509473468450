.toggle-switch {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggle-switch.on {
    background-color: #828282;
  }
  
  .toggle-thumb {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.3s;
  }
  
  .toggle-switch.on .toggle-thumb {
    left: 25px;
  }