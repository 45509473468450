.budget-slider-container {
  position: relative;
}

.slider-value {
  position: absolute;
  top: -30px;
  transform: translateX(-40%);
  font-size: 16px;
  background-color: #fff;
  padding: 2px 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  pointer-events: none;
}

.slider {
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0a1045;
  cursor: pointer;
  position: relative;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0a1045;
  cursor: pointer;
  position: relative;
}

.slider-values {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
