@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

:root {
  --leazy-blue: #0a1045;
  --leazy-yellow: #e8aa14;
  --placeholder-color: rgba(0, 0, 0, 0.3);
  --warning-red: #d62828;
  --checklist-green: #008000;
  --password-requirements-text: #4c4a53;
  --header-color: #27252e;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  width: 24px;
  height: 24px;
}

/* Mobile */
.text-input-primary {
  width: 100%;
  height: 7vh;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  color: var(--placeholder-color);
  font-family: "Inter";
  font-weight: 400;
  padding-left: 18px;
  padding-right: 40px;
  box-sizing: border-box;
}

.text-input-primary:focus {
  outline: none;
  border: 2px solid black;
  color: black;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.sign-up-continue {
  min-width: 89.33%;
  height: 7vh;
  background-color: var(--leazy-blue);
  color: white;
  border: 2px solid var(--leazy-blue);
  border-radius: 10px;
  font-weight: bold;
  margin-inline: 5.33%;
  margin-top: 6%;
}

.sign-up-continue:hover {
  cursor: pointer;
  background-color: #0a1045;
  opacity: 0.5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 15.3%;
}

.back-icon {
  position: absolute;
  left: 0;
  font-size: 25px;
  width: 15px;
  height: 14px;
  margin-left: 5.333%;
  cursor: pointer;
}

.signup-header {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.login-btn {
  width: 343px;
  height: 60px;
  border-radius: 12px;
  color: white;
  background-color: var(--leazy-blue);
  font-weight: 15;
}

.input-container {
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  margin-inline: 5%;
}

.container-info {
  min-height: 80vh;
}

.terms-of-services {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 285px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

.terms-of-services p {
  margin: 0;
}

.terms-of-services a {
  color: #4b71f7;
  font-weight: bold;
}

/* Desktop */
@media (min-width: 768px) {
  .container-info {
    min-height: 60vh;
  }

  .header-container {
    margin-top: 5%;
  }

  .main-container {
    border: 3px solid white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    max-width: 60vw;
    margin: auto;
    margin-block: 15%;
  }

  .terms-of-services {
    margin-bottom: 5%;
  }
}

@media (min-width: 1024px) {
  .main-container {
    max-width: 40vw;
    margin-block: 6%;
  }
}
