.web-landing-nav {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}

.web-landing-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  gap: 2.5%;
  padding: 0;
  margin: 0;
}

.web-landing-nav div {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  position: relative;
}

.web-landing-nav div:hover {
  background-color: #d0d0d0;
  border-radius: 13px;
}

.web-landing-nav .active {
  background-color: white;
  border-radius: 13px;
}

.web-landing-nav .active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

.web-navbar-icon {
  font-size: 24px;
  color: #575757;
  margin-right: 10px;
}

.web-landing-nav li {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #575757;
  margin: 0;
}
