.suggestions-body {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suggestions-header {
  align-items: center;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  font-family: "Inter";
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 10px;
}

.suggestions-inner-header {
  padding: 2% 0;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
}

.suggestions-container {
  margin-top: 15%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  width: 90%;
  border-radius: 10px;
  position: relative;
}

.headshot-details {
  align-items: center;
  margin-bottom: 10px;
}

.profile {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.user-picture {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #d3d3d3;
  margin-right: 10px;
  overflow: hidden;
}

.user-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  margin: auto 0 3%;
  font-size: 18px;
  font-weight: 100;
  font-family: "Roboto";
}

.report-user-account {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 14px;
  color: black;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0px;
}

.program-and-age {
  gap: 2%;
  display: flex;
  margin-top: 10px;
}

.program-and-age div {
  align-items: center;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  background-color: #0a1045;
  color: #fff;
  padding: 3px 15px;
  border-radius: 10px;
}

.break-line {
  border: 1px solid #eaeaea;
  margin-block: 4%;
}

.user-details {
  flex-grow: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.interest-container,
.bio-container {
  margin-bottom: 10px;
}

.user-interests {
  margin: 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.interest-item {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  background-color: #0a1045;
  color: #fff;
  padding: 2px 10px;
  border-radius: 10px;
}

.add-interest {
  color: black;
  font-size: 15px;
  margin-right: 5px;
}

.interest-name {
  flex-grow: 1;
  text-align: center;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

.bio-container div:last-child {
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.choice {
  width: 100%;
  min-height: 25vh;
  display: flex;
  justify-content: space-evenly;
}

.choice div {
  font-size: 70px;
  cursor: pointer;
}

.choice div:first-child {
  color: #008000;
}

.choice div:last-child {
  color: #d62828;
}

.property-header {
  margin-block: 5%;
  text-align: center;
  width: 100%;
  font-family: "Inter";
  font-weight: bold;
  font-size: 20px;
}

.property-details {
  width: 90%;
  height: auto;
  margin-bottom: 10px;
}

.property-photos {
  border-radius: 15px;
}

.property-photos img {
  width: 100%;
  border-radius: 15px;
}

.property-title {
  color: #27252e;
  font-family: "Inter";
  font-weight: bold;
  font-size: 24px;
  margin-top: 10%;
  margin-bottom: 5%;
}

.property-description {
  margin-bottom: 10px;
}

.property-description {
  font-family: "Roboto";
  font-size: 15px;
}

.property-description div:last-child {
  font-family: "Inter";
  font-size: 14px;
  margin-bottom: 5%;
}

.offerings-amenities {
  margin-bottom: 10px;
}

.property-details-inner-header {
  font-size: 17px;
  font-weight: 500;
  font-family: "Roboto";
  margin-bottom: 5%;
}

.dropdown-arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .suggestions-body {
    height: 90vh;
    margin: 1% auto;
    width: 90%;
    flex-direction: row;
    justify-content: center;
    gap: 5%;
    border-radius: 10px;
    box-shadow: rgba(243, 243, 243, 0.25) 0px 30px 60px -12px inset, rgba(180, 180, 180, 0.3) 0px 18px 36px -18px inset;
  }

  .report-user-account {
    left: 0px;
  }

  .suggestions-header {
    display: none;
    font-size: 45px;
  }

  .suggestions-inner-header {
    padding: 2% 0 2% 0%;
    font-size: 20px;
  }

  .suggestions-container {
    height: 85vh;
    width: 45%;
    margin: 20px;
    display: flex;
    flex-direction: column;
  }

  .profile {
    margin-top: 5%;
    flex-direction: column;
  }

  .user-picture {
    width: 150px;
    height: 150px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .user-name {
    font-size: 24px;
    text-align: center;
  }

  .program-and-age {
    justify-content: center;
  }

  .user-interests {
    width: 80%;
    grid-template-columns: repeat(5, 1fr);
  }

  .choice {
    min-height: 10vh;
    margin-bottom: 10px;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .choice div {
    font-size: 80px;
  }

  .property-details {
    overflow-y: scroll;
    height: 80vh;
    width: 40%;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .property-title {
    margin-top: 10%;
    font-size: 30px;
  }

  .property-description {
    font-size: 18px;
  }

  .property-details-inner-header {
    font-size: 20px;
  }

  .property-header {
    display: none;
  }

  .description-content {
    max-height: 8vh;
    overflow-y: scroll;
  }
}
.view-more {
  font-weight: bold;
}

.no-more-suggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #575757;
}

.fa-info {
  color: var(--leazy-blue);
  margin-bottom: 5%;
  font-size: 50px;
}

.no-more-suggestions p {
  margin: 0 auto;
  padding-inline: 5%;
  font-size: 20px;
}

.slick-slide img {
  height: 40vh;
  display: block;
}
