.text-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
}

.text-wrapper p {
  font-size: 16px;
}

.verification-code-input {
  display: flex;
  justify-content: center;
  max-width: 360px;
  min-width: 89.33%;
  margin-inline: 5.33%;
  margin-top: 8%;
}

.code-input-box {
  width: 50px;
  height: 50px;
  text-align: center;
  font-family: "Inter";
  font-weight: bold;
  font-size: 24px;
  border: 2px solid #00000042;
  border-radius: 15px;
  padding: 10px;
  margin-right: 10px;
  box-sizing: border-box;
}

.code-input-box:last-child {
  margin-right: 0;
}

.code-input-box:focus {
  border-color: #000;
  outline: none;
}

.timer-wrapper {
  display: inline-block;
  background-color: #eaeaea;
  border-radius: 50%;
  padding: 10px;
  /* font-weight: bold; */
  font-size: 15px;
  font-family: "Inter";
}
