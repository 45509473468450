.photo-upload-container {
  margin-top: 5%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 87.9%;
  min-height: 24vh;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.photos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding-left: 5px;
}

.photo {
  position: relative;
  flex-shrink: 0;
  width: 100px;
  height: 20vh;
}

.photo > div {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 5px;
  font-size: 20px;
  cursor: pointer;
}

.photo {
  margin-left: 5px;
  margin-right: 5px;
}

.property-upload-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.add-photos {
  flex-shrink: 0;
  width: 100px;
  height: 20vh;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #eaeaea;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
  text-align: center;
}

.add-photos svg {
  margin-bottom: 5px;
}

.add-photos p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #575757;
}

.square-plus-icon {
  font-size: 25px;
  color: #575757;
}

@media (max-height: 800px) {
  .add-photos p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #575757;
  }
  .property-upload-image {
    width: 100%;
  }
}
