.onboarding-container {
  min-height: 100vh;
}

.onboarding-progress-bar-container {
  max-width: 89.333%;
  margin-inline: 5.6%;
  margin-top: 15%;
  height: 8px;
  background-color: #ededed;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar-filler {
  height: 100%;
  transition: width 0.3s ease-in-out;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media (min-width: 768px) {
  .onboarding-container {
    max-width: 60vw;
    margin-inline: 25%;
  }

  .circles {
    display: none;
  }

  .onboarding-progress-bar-container {
    margin-top: 8%;
  }
}
