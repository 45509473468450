html,
body {
  overflow: hidden;
}

.page-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 35px;
  margin-inline: 5.66%;
}

.content {
  margin-inline: 5.66%;
  max-height: 65vh;
  border: 1px solid #f1f0f0;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.content h3 {
  margin-left: 4%;
}

.content p {
  margin-inline: 5%;
}

.agree-btn {
  width: 15px;
  height: 15px;
  background-color: #d9d9d9;
  border: none;
}

.agree-btn:hover {
  cursor: pointer;
}

label {
  text-align: center;
  margin-left: 10%;
  color: #4b71f7;
  font-family: "Inter";
  font-weight: 600;
}

.agree-container {
  align-items: center;
  margin-block: 10%;
  display: flex;
  width: 100%;
}

.agree-group,
.next {
  width: 50%;
}

.agree-group {
  margin-left: 10%;
}

.next {
  margin-right: 10%;
  text-align: right;
}

.next:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .content {
    height: 70vh;
  }
  .container {
    max-width: 70vw;
    max-height: 80vh;
  }
}

@media (max-height: 700px) {
  .content {
    margin-inline: 5.66%;
    min-height: 60vh;
    max-height: 60vh;
    border: 1px solid #f1f0f0;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
