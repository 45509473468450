@keyframes explode {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  70% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.check-icon {
  height: 70px;
  color: #4b4b4b;
  animation: explode 0.6s ease-out forwards;
}

.success-icon {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
}

.success-header-container {
  margin-top: 0%;
}

.success-container-info {
  min-height: 60vh;
}

@media (min-width: 768px) {
  .success-icon {
    margin-top: 5%;
  }

  .success-container-info {
    min-height: 60vh;
  }
}
