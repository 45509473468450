.dropdown-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5%;
}

.dropdown-header {
  min-height: 4vh;
  padding: 10px 12px;
  border: 2px solid #eaeaea;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #707070;
}

.dropdown-arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
  font-size: 12px;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-list-container {
  min-height: 4vh;
  position: absolute;
  width: 100%;
  border: 2px solid #eaeaea;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
  border-radius: 5px;
  margin-top: 2px;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 16vh;
  overflow-y: auto;
}

.dropdown-list-item {
  min-height: 3vh;
  padding: 10px 12px;
  cursor: pointer;
  font-size: 16px;
  color: #707070;
  border-bottom: 1px solid #dcdcdc;
}

.dropdown-list-item:hover {
  background: #f1f1f1;
}

.dropdown-list-item:last-child {
  border-bottom: none;
}


@media (min-width: 800px){
  .dropdown-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 3%;
  }
}