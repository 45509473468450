.personal-info-step-one {
  min-height: 70vh;
}

.personal-info-step-one label {
  color: #828282;
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter";
}

.personal-info-input-container {
  margin-top: 10%;
  height: 65vh;
  margin-inline: 5.66%;
}

.personal-info-input-container-two {
  margin-top: 10%;
  height: 50vh;
  margin-inline: 5.66%;
}

.profile-upload-container {
  margin-top: 15%;
  margin-bottom: 15%;
  display: flex;
  align-items: center;
}

.profile-upload-icon {
  color: white;
  font-size: 50px;
}

.upload-circle {
  background: #eaeaea;
  height: 11vh;
  width: 11vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  overflow: hidden;
}

.upload-circle:hover {
  cursor: pointer;
}

.profile-upload-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.upload-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  color: #828282;
}

.custom-input {
  max-width: 100%;
  display: flex;
  gap: 20px;
}

.city-input {
  flex: 3;
}

.age-input {
  flex: 1;
}

.city-input {
  width: 65%;
}

.age-input {
  width: 20%;
}

.city-input,
.age-input {
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  text-align: left;
  padding-left: 10px;
  transition: border 0.3s;
  color: #828282;
}

.city-input:focus,
.age-input:focus {
  outline: none;
  border: 1px solid black;
  color: black;
}

.budget-slider p {
  text-align: center;
  font-family: "Inter";
  font-weight: 400px;
  font-size: 16px;
  color: #707070;
}

.reminder {
  width: 89.9%;
  margin-inline: 5.66%;
}

.reminder p {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
  color: black;
  opacity: 30%;
}

.personal-info-step-two {
  min-height: 70vh;
}

/* DESKTOP STYLING */
@media (min-width: 768px) {
  .personal-info-input-container {
    height: 60vh;
    margin-inline: 10%;
  }

  .personal-info-step-two {
    min-height: 100vh;
    max-width: 80%;
  }

  .personal-info-input-container-two {
    height: 55vh;
    margin-top: 5%;
  }

  .profile-upload-container {
    margin-block: 8%;
  }

  .profile-upload-container {
    margin-block: 8%;
  }

  .upload-circle {
    height: 13vh;
    width: 13vh;
  }
}

/* SMALL PHONE STYLING */
@media (max-height: 800px) {
  .personal-info-step-two {
    min-height: 100vh;
  }

  .personal-info-input-container {
    margin-top: 10%;
    height: 60vh;
  }

  .profile-upload-icon {
    color: white;
    font-size: 35px;
  }

  .personal-info-input-container-two {
    margin-top: 10%;
    height: 50vh;
  }

  .reminder p {
    font-family: "Roboto";
    font-size: 17px;
    font-weight: 400;
    color: black;
    opacity: 30%;
  }
}
