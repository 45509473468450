@media (max-width: 320px) {
  .requirements {
    margin-inline: 7%;
  }
}

.requirements {
  margin-top: 20px;
  margin-inline: 6%;
  color: #4c4a53;
  font-family: "Roboto", sans-serif;
}

.requirement-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid grey;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.circle.met {
  background-color: white;
  border: 2px solid white;
}

.requirement-item span {
  font-size: 14px;
  color: #333;
}
